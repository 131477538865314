// src/context/ApiProvider.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import fetchWithToken from '../utils/api';
import { getToken } from '../tokenUtils/tokenUtils';
import { ApiContextType } from '../types';


const ApiContext = createContext<ApiContextType | undefined>(undefined);

export const ApiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const initializeToken = async () => {
      try {
        const initialToken = await getToken();
        setToken(initialToken);
      } catch (error) {
        console.error('Error initializing token:', error);
      }
    };
    initializeToken();
  }, []);

  const refreshToken = async () => {
    try {
      const newToken = await getToken(true);
      setToken(newToken);
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };

  return (
    <ApiContext.Provider value={{ fetchWithToken, token, refreshToken }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = (): ApiContextType => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
};
