// src/wrap-with-provider.tsx
import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ApiProvider } from './context/ApiContext';

const queryClient = new QueryClient();

interface WrapRootElementProps {
  element: ReactNode;
}

export const wrapRootElement = ({ element }: WrapRootElementProps) => (
  <QueryClientProvider client={queryClient}>
    <ApiProvider>{element}</ApiProvider>
  </QueryClientProvider>
);
