// utils/tokenUtils.ts
import axios from 'axios';
import { Buffer } from 'buffer';

const TOKEN_KEY = 'api_token';
const TOKEN_EXPIRY_KEY = 'token_expiry';

const validateEnvVariables = () => {
  if (!process.env.GATSBY_PMS_USERNAME || !process.env.GATSBY_PMS_PASSWORD || !process.env.GATSBY_PMS_BASE_URL) {
    throw new Error('Environment variables for PMS API are not properly configured.');
  }
};

const fetchToken = async (): Promise<string> => {
  try {
    validateEnvVariables();
    const authHeader =
      'Basic ' +
      Buffer.from(`${process.env.GATSBY_PMS_USERNAME}:${process.env.GATSBY_PMS_PASSWORD}`).toString('base64');
    const loginResponse = await axios.get(`${process.env.GATSBY_PMS_BASE_URL}/api/v1/login`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader,
      },
    });
    return loginResponse.data.token;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw new Error('Unable to fetch token');
  }
};

const storeToken = (token: string) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(TOKEN_EXPIRY_KEY, (Date.now() + 24 * 3600 * 1000).toString()); // Token expires in 24 hours
  } else {
    console.warn('Token cannot be stored as localStorage is not available');
  }
};

const getToken = async (forceRefresh: boolean = false): Promise<string> => {
  if (!forceRefresh && typeof window !== 'undefined' && window.localStorage) {
    const token = localStorage.getItem(TOKEN_KEY);
    const tokenExpiryTime = localStorage.getItem(TOKEN_EXPIRY_KEY);

    if (token && tokenExpiryTime && Date.now() < parseInt(tokenExpiryTime)) {
      return token;
    }
  }

  const newToken = await fetchToken();
  storeToken(newToken);
  return newToken;
};

export { getToken, storeToken };
